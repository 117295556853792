import Head from "next/head";
import { useState, useEffect, useCallback } from "react";
import { useRouter } from "next/router";
import { useSWRConfig } from "swr";
import Source from "../components/source";
import { useUser } from "../functions/useRequests";

export default function Index({ queryRedirect }) {
	const router = useRouter();
	const [redirect, setRedirect] = useState(false);

	const { user, isLoggedIn, isLoading } = useUser();

	const startRedirect = useCallback(async () => {
		setRedirect("Processing");
		const res = await fetch(`/api/auth/loginurl?domain=${window.location.origin}&redirect=${queryRedirect}`);
		const json = await res.json();
		document.cookie = `AUTH_REDIRECT=${json.cookie}; Max-Age=${30 * 24 * 30 * 60};Path=/`;
		console.log("Setting cookie", json.cookie);
		setTimeout(() => { router.push(json.url); }, 200);
	}, [queryRedirect, router]);

	useEffect(() => {
		async function attemptGenerate() {
			setRedirect("Processing");
			const res = await fetch(`/api/auth/generate?redirect=${queryRedirect}`);
			const json = await res.json();
			if (json.code) window.location.assign(`${queryRedirect}?code=${json.code}`);
			else setRedirect("Auth Issue");
		}
		if (queryRedirect && !isLoading) {
			if (isLoggedIn) attemptGenerate();
			else startRedirect();
		}
	}, [user, isLoggedIn, isLoading, queryRedirect, startRedirect]);

	const [logout, setLogout] = useState(false);
	const { mutate } = useSWRConfig();
	async function startLogout() {
		setLogout(true);
		await fetch("/api/auth/logout", { method: "post", credentials: "include" });
		mutate("/api/auth/@me");
		setLogout(false);
	}

	return (
		<div className="">
			<Head>
				<title>John Jay Robotics Auth Service</title>
				<Source />
			</Head>
			<div className="bg-gray-900 py-20 min-h-screen">
				<div className="flex max-w-sm mx-auto my-10 md:my-24 rounded-lg shadow-lg bg-gray-800 lg:max-w-4xl">
					<div className="hidden bg-cover lg:block lg:w-1/2" style={{ backgroundImage: "url('https://cdn.discordapp.com/attachments/769275863786455057/913234503617626182/Tempfile.png')" }}></div>
					<div className="w-full px-6 py-8 md:px-8 lg:w-1/2">
						<h2 className="text-2xl font-semibold text-center text-white">Robotics Auth</h2>
						<p className="text-md text-center text-gray-200">
							{!isLoggedIn && "You are not currently signed in."}
							{isLoggedIn && <>
								Welcome, <strong>{user.first_name}</strong>. <button onClick={startLogout} className="underline hover:underline-offset-1">Not you?</button> Head back to any site powered by <strong>Jaybots Auth</strong> to continue.
							</>}
						</p>
						{(!isLoading && isLoggedIn && !queryRedirect && !logout) && <>
							<a onClick={startRedirect} className="cursor-not-allowed flex items-center justify-center mt-4 border rounded-lg bg-gray-700 border-gray-700 text-gray-200 opacity-75">
								<span className="w-5/6 px-4 py-3 font-bold text-center">Error: No Redirect Provided</span>
							</a>
						</>}
						{(redirect !== "Processing" && !logout && !isLoggedIn && !queryRedirect && !isLoading) && <>
							<a onClick={startRedirect} className="flex items-center justify-center mt-4 border rounded-lg bg-gray-700 dark:border-gray-700 text-gray-200 hover:bg-gray-600">
								<div className="px-4 py-2">
									<svg className="w-6 h-6" viewBox="0 0 40 40">
										<path d="M36.3425 16.7358H35V16.6667H20V23.3333H29.4192C28.045 27.2142 24.3525 30 20 30C14.4775 30 10 25.5225 10 20C10 14.4775 14.4775 9.99999 20 9.99999C22.5492 9.99999 24.8683 10.9617 26.6342 12.5325L31.3483 7.81833C28.3717 5.04416 24.39 3.33333 20 3.33333C10.7958 3.33333 3.33335 10.7958 3.33335 20C3.33335 29.2042 10.7958 36.6667 20 36.6667C29.2042 36.6667 36.6667 29.2042 36.6667 20C36.6667 18.8825 36.5517 17.7917 36.3425 16.7358Z" fill="#FFC107"/>
										<path d="M5.25497 12.2425L10.7308 16.2583C12.2125 12.59 15.8008 9.99999 20 9.99999C22.5491 9.99999 24.8683 10.9617 26.6341 12.5325L31.3483 7.81833C28.3716 5.04416 24.39 3.33333 20 3.33333C13.5983 3.33333 8.04663 6.94749 5.25497 12.2425Z" fill="#FF3D00"/>
										<path d="M20 36.6667C24.305 36.6667 28.2167 35.0192 31.1742 32.34L26.0159 27.975C24.3425 29.2425 22.2625 30 20 30C15.665 30 11.9842 27.2359 10.5975 23.3784L5.16254 27.5659C7.92087 32.9634 13.5225 36.6667 20 36.6667Z" fill="#4CAF50"/>
										<path d="M36.3425 16.7358H35V16.6667H20V23.3333H29.4192C28.7592 25.1975 27.56 26.805 26.0133 27.9758C26.0142 27.975 26.015 27.975 26.0158 27.9742L31.1742 32.3392C30.8092 32.6708 36.6667 28.3333 36.6667 20C36.6667 18.8825 36.5517 17.7917 36.3425 16.7358Z" fill="#1976D2"/>
									</svg>
								</div>
								<span className="w-5/6 px-4 py-3 font-bold text-center">{redirect || "Sign in with Google"}</span>
							</a>
						</>}
						{(redirect === "Processing" || logout || queryRedirect || isLoading) && <>
							<a className="justify-center cursor-wait flex items-center mt-4 border rounded-lg bg-gray-700 border-gray-700 text-gray-200 opacity-75">
								<span className="w-5/6 px-4 py-3 font-bold text-center justify-center inline-flex">
									<svg className="w-5 h-5 text-white animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
										<circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
										<path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
									</svg>
								</span>
							</a>
						</>}

						<div className="flex items-center justify-between mt-4">
							<span className="w-1/5 border-b border-gray-600 lg:w-1/3"></span>
							<a className="text-xs text-center uppercase text-gray-400">Other Links</a>
							<span className="w-1/5 border-b border-gray-400 lg:w-1/3"></span>
						</div>

						<div className="mt-4">
							<button className="w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform focus:outline-none rounded bg-gray-700 hover:bg-gray-600 focus:bg-gray-600">
								More About John Jay Robotics
							</button>
							<button className="w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform focus:outline-none rounded bg-gray-700 hover:bg-gray-600 focus:bg-gray-600 mt-2">
								Join the Jaybots?
							</button>
							<button className="w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform focus:outline-none rounded bg-gray-700 hover:bg-gray-600 focus:bg-gray-600 mt-2">
								Jaybots Booster Club
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export async function getServerSideProps({ query }) {
	return { props: { queryRedirect: query.redirect || null } };
}
