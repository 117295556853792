export default function source() {
	return (
		<>
			<link rel="icon" href="https://cdn.jaybots.org/logo/logo.png" />
			<meta name="theme-color" content="#111827" />
			<meta name="description" content="Auth service for the John Jay Robotics Club." />
			<meta name="twitter:card" value="summary" />
			<meta name="twitter:title" content="Auth - John Jay Robotics Club" />
			<meta name="twitter:description" content="Auth service for the John Jay Robotics Club." />
			<meta property="twitter:image" content="https://cdn.jaybots.org/logo/logo.png" />
			<meta property="og:title" content="Auth - John Jay Robotics Club" />
			<meta property="og:description" content="Auth service for the John Jay Robotics Club." />
			<meta property="og:image" content="https://cdn.jaybots.org/logo/logo.png" />
			<meta property="og:site_name" value="John Jay Robotics Club" />
		</>
	);
}
