import useSWR from "swr";

export const fetcher = (url) => fetch(url, { credentials: "include" }).then((res) => res.json());

export const useUser = () => {
	const { data, error } = useSWR("/api/auth/@me", fetcher, { focusThrottleInterval: 10000, dedupingInterval: 4000 });
	return {
		user: data,
		isLoading: !error && !data,
		isLoggedIn: data && !data.error,
		isError: error,
	};
};

export const useRequest = (url) => {
	const { data, error } = useSWR(url, fetcher);
	return {
		data,
		isLoading: !error && !data,
		isError: error,
	};
};
